<template>
	<modal ref="refModal" titulo="Editar CEDIS" tamano="modal-md" cerrar noAceptar no-cancelar adicional="Guardar"
		@adicional="handleEditCedis">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-9">
				<div class="row mx-0 mb-3">
					<p class="f-12 text-black2 pl-3">Añadir Cedis</p>
					<el-select v-model="value" placeholder="Selecciona" class="w-100" size="small" @change="addCedis">
						<el-option v-for="item in selectCedis" :key="item.id" :label="item.nombre" :value="item.id"
							:disabled="item.disabled" />
					</el-select>
				</div>
				<div v-for="(c, key) in model.cedis" :key="key" class="d-middle-bt my-3">
					<div class="border br-5 bg-whitesmoke text-general fr-light px-2 f-14 py-1 w-250px">
						{{ c.nombre }}
					</div>
					<el-switch v-if="c.id_paqueteria" v-model="c.estado" :active-value="1" :inactive-value="null"
						active-color="#28D07B" inactive-color="#DBDBDB" @change="cambiarEstadoCuenta(c.id, $event)" />
					<i class="icon-delete-trash f-20 text-black2 cr-pointer" @click="removerCedis(c)" />
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
import Servicio from "~/services/serviciosPaqueteria";
export default {
	data() {
		return {
			idServicio: parseInt(this.$route.params.id),
			value: '',
			estado: 1,
			id_cuenta: null,
			cedis: [],
			cedisSelect: [],
			model: {
				cedis: []
			}
		}
	},
	computed: {
		selectCedis() {
			return this.cedis_calculo.filter(o => o.id != 0)
		}
	},
	methods: {
		toggle(cedis) {
			this.value = '';
			this.cedis = [];
			this.model.cedis = cedis;
			this.gestionCedis(cedis);
			this.$refs.refModal.toggle();
		},
		gestionCedis(cedis) {
			try {
				this.selectCedis.map(e => e.disabled = false)

				for (const c of cedis) {
					let cedi = this.selectCedis.find(o => o.id == c.id_cedis);
					if (cedi) {
						cedi.disabled = true
					}
				}


			} catch (error) {
				return error_catch(error)
			}
		},
		addCedis(id) {
			let c = this.selectCedis.find(o => o.id === id)
			c.disabled = true
			c.id_cedis = c.id
			this.model.cedis.push(c)
			this.id_cedis = null
		},
		removerCedis(data) {
			try {
				if (data.id_paqueteria) {
					this.eliminarCedisServicio(data.id);
					this.selectCedis.find(o => o.id === data.id_cedis).disabled = false;
					const idx = this.model.cedis.findIndex((e) => e.id_cedis == data.id)
					this.model.cedis.splice(idx, 1)
				} else {
					this.selectCedis.find(o => o.id === data.id_cedis).disabled = false;
					const idx = this.model.cedis.findIndex((e) => e.id_cedis == data.id_cedis)
					this.model.cedis.splice(idx, 1)
				}
				this.value = ''
				this.$refs.refModal.toggle();
			} catch (e) {
				return this.error_catch(e)
			}
		},

		async handleEditCedis() {
			try {
				const payload = {
					idsCedis: this.model.cedis.map(element => element.id_cedis)
				}

				const { data } = await Servicio.editListaCedis(this.idServicio, payload)
				this.notificacion("", data.mensaje, "success")
				this.$emit('update')
				this.$refs.refModal.toggle();
			} catch (e) {
				return this.error_catch(e)
			}
		},
		async cambiarEstadoCuenta(id, event) {
			try {
				const payload = {
					estado: event,
					idCedisPaqueteria: id
				}
				const { data } = await Servicio.editEstadoCedis(payload)
				this.notificacion('', data.mensaje, "success")
			} catch (e) {
				return this.error_catch(e)
			}
		},
		async eliminarCedisServicio(id) {
			try {
				const { data } = await Servicio.deleteCedisServicio(id)
				this.notificacion('', data.mensaje, "success")
				this.$emit('update')
			} catch (e) {
				return this.error_catch(e)
			}
		}
	}
}
</script>
